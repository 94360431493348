import {hasGuestsGoing} from '@wix/wix-events-commons-statics/dist/members'
import {isFullWidth} from '../../commons/selectors/environment'
import {isRsvp} from '../../commons/selectors/event'
import {isFirstEventCreated} from '../../commons/selectors/site-settings'
import {AppProps} from '../components/app/interfaces'
import {MEDIUM_WIDTH, MOBILE_WIDTH} from '../components/list-layout/constants'
import {IMAGE_RATIO, LIST_LAYOUT} from '../constants/settings'
import {State} from '../types/state'
import {getEvents, getEventById} from './events'

export const isListLayout = (state: State) => state.component.settings.listLayout === LIST_LAYOUT.LIST

export const isCardsLayout = (state: State) => state.component.settings.listLayout === LIST_LAYOUT.NEW_GRID

export const isCalendarLayout = (state: State) => state.component.settings.listLayout === LIST_LAYOUT.CALENDAR

export const isNarrow = (state: State, dimensions: AppProps['dimensions']) =>
  !isFullWidth(state, dimensions) && dimensions.width < MOBILE_WIDTH

export const isMainTitleVisible = (state: State) => state.component.settings.listShowMainTitle

export const isDateVisible = (state: State) => state.component.settings.listShowDate

export const isVenueVisible = (state: State, dimensions: AppProps['dimensions']) => {
  if (isListLayout(state)) {
    return state.component.settings.listShowVenueName && dimensions.width > MEDIUM_WIDTH
  }

  return state.component.settings.listShowVenueName
}

export const isFullDateVisible = (state: State) => state.component.settings.listShowFullDate

export const isLocationVisible = (state: State) => state.component.settings.listShowLocation

export const isDescriptionVisible = (state: State) => state.component.settings.listShowDescription

export const isSocialShareVisible = (state: State) => state.component.settings.listShowSocialShare

export const isImageVisible = (state: State) => state.component.settings.listShowImage

export const isMembersEnabled = (state: State) => state.membersEnabled && state.component.settings.listShowMembers

export const isMembersVisible = (state: State, event: ExtendedEvent) =>
  isMembersEnabled(state) && hasGuestsGoing(event.totalGuests, isRsvp(event))

export const isAdditionalComponentsHidden = (state: State, eventId?: string) => {
  const descriptionVisible = eventId
    ? isDescriptionVisible(state) && !!getEventById(state, eventId).description
    : isDescriptionVisible(state)

  return (
    !state.component.settings.listShowAdditionalInfo ||
    (!isFullDateVisible(state) && !isLocationVisible(state) && !descriptionVisible && !isSocialShareVisible(state))
  )
}

export const isMobileSquareImage = (state: State) =>
  isCardsLayout(state) && state.component.settings.imageRatio === IMAGE_RATIO['1:1']

export const getImageMargins = (state: State, dimensions: AppProps['dimensions'], rtl: boolean) =>
  isNarrow(state, dimensions) ? {marginBottom: 20} : {[rtl ? 'marginLeft' : 'marginRight']: 22}

export const isEmptyStateNotificationVisible = (state: State) =>
  isFirstEventCreated(state.siteSettings) && !getEvents(state).length
