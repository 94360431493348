import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {getSiteSettings} from '../../commons/actions/site-settings'
import {MembersComponentConfig} from '../../commons/types/members-settings'
import {CommonState} from '../../commons/types/state'
import {getComponentData, updateComponent} from '../actions/component'
import {closeAllEvents, getEvents, setTab, shareEvent, toggleEventDetails} from '../actions/events'
import {internalNavigate, navigateToDetailsPage} from '../actions/navigation'
import {cancelRsvp} from '../actions/rsvp'
import {updateSettings} from '../actions/sdk'
import {downloadTicketsAction} from '../actions/tickets'
import {resetToLiveView} from '../actions/view'
import {Api} from '../utils/api'

export type GetState = () => MemberPageState

export interface MemberPageContext {
  state: MemberPageState
  actions: Actions
  cssBaseUrl: string
  isRTL: boolean
}

export interface MemberPageState extends CommonState {
  component: MembersComponentConfig
  user: UserConfig
  events: Events
  expandedEvents: string[]
  tab: Tabs
  loading: boolean
  navigation: Navigation
  demoEvents: Events
  view: View
}

export interface UserConfig {
  currentUserId: string
  viewedSiteMemberId: string
}

export interface StoreExtraArgs {
  serverApi: Api
  wixCodeApi: IWixAPI
  compId: string
  baseUrl: string
  instance: string
  pageUrl: string
}

export interface DetailedEvent {
  event: wix.events.Event
  orders: wix.events.ticketing.Order[]
  rsvp: wix.events.rsvp.Rsvp
}

export enum EventsList {
  PAST = 'past',
  UPCOMING = 'upcoming',
}

interface EventsTotal {
  [EventsList.PAST]: number
  [EventsList.UPCOMING]: number
}

export interface Events {
  [EventsList.PAST]: DetailedEvent[]
  [EventsList.UPCOMING]: DetailedEvent[]
  total: EventsTotal
}

export interface Navigation {
  noUpcomingEventsLinkPageUrl: string
  noUpcomingEventsLinkPageId: string
  route: Routes
  routeParams: RouteParams
}

export interface View {
  demo: boolean
  noEvents: boolean
}

export interface Actions {
  getEvents: typeof getEvents
  getComponentData: typeof getComponentData
  toggleEventDetails: typeof toggleEventDetails
  cancelRsvp: typeof cancelRsvp
  shareEvent: typeof shareEvent
  getSiteSettings: typeof getSiteSettings
  navigateToDetailsPage: typeof navigateToDetailsPage
  updateSettings: typeof updateSettings
  updateComponent: typeof updateComponent
  setTab: typeof setTab
  resetToLiveView: typeof resetToLiveView
  internalNavigate: typeof internalNavigate
  downloadTicketsAction: typeof downloadTicketsAction
  appLoaded: Function
  closeAllEvents: typeof closeAllEvents
}

export enum Tabs {
  UPCOMING = 0,
  PAST = 1,
}

export enum Routes {
  DEFAULT = 'DEFAULT',
  ORDER_LIST = 'ORDER_LIST',
  ORDER = 'ORDER',
}

export interface RouteParams {
  event?: DetailedEvent
  order?: wix.events.ticketing.Order
}
